import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react'
import Modal from 'react-modal'
import moment from 'moment';
import './App.scss';

// import Mockup4 from './mockup4.json' // Test
// import Mockup8 from './mockup8.json' // Test
// import Mockup12 from './mockup12.json' // Test

import Logo from './assets/img/banner.png'
import GrandprizeActive from './assets/img/grandprize_active.png'
import GrandprizeDisable from './assets/img/grandprize_disable.png'
import NoPrize from './assets/img/no_prize.png'
import Item1 from './assets/img/1.png'
import Item2 from './assets/img/2.png'
import Item3 from './assets/img/3.png'
import Item4 from './assets/img/4.png'
import Item5 from './assets/img/5.png'
import Item6 from './assets/img/6.png'
import Item7 from './assets/img/7.png'
import Item8 from './assets/img/8.png'
import Item9 from './assets/img/9.png'
import Item10 from './assets/img/10.png'
import Item11 from './assets/img/11.png'
import Item12 from './assets/img/12.png'
// import PlaceholderTest from './assets/img/placeholder_test.png'
import Celebrate from './assets/img/celebrate.gif'


function App() {
  const [hasError, setHasError] = useState(false);
  const [dataCampaign, setDataCampaign] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const [itemIndex, setItemIndex] = useState(0)
  const [modalDetailPrize, setModalDetailPrize] = useState(false)
  const [modalDetailGrandPrize, setModalDetailGrandPrize] = useState(false)

  const [windowWidthValue, setWindowWidthValue] = useState(window.innerWidth)
  // const [windowHeightValue, setWindowHeightValue] = useState(window.innerHeight)

  const [loginFirst, setLoginFirst] = useState(false)
 
  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const query = url.searchParams.get("query");
    // console.log(url.pathname)
    console.log('Edit.V.1.230622')
    let unamounted = false

    // Fetch API
    async function fetchDataCampaign () {
      const queryData = query || '';
      const queryFinal = queryData.split(' ').join('%2B');
      // const campaignID = idFromPathname;
      const apiXKey = 'b5e64ba6-1d27-4c40-bc65-2e686e394d62';

      const apiPath = process.env.REACT_APP_API

      const res = await fetch(`${apiPath}`, {
          method: 'POST',
          body: JSON.stringify({
              "query": queryFinal,
              // "campaign_id": campaignID
          }),
          headers: {
              "x-application-secret-key": apiXKey,
              "Content-Type": "application/json",
              "Accept": "application/json"
          }
      }); 

      res
        .json()
        .then(res => {
          let resData =  res // Test Mockup12 // Mockup8 // Mockup4 // res;
          if(!unamounted) {
            if (res.success === 0 || res === null || res === undefined ) {
              setIsLoaded(true);
              setHasError(true);
            } else {
              setLoginFirst(true)

              if(res.active_campaign === 0) { // Test !res.active_campaign === 0
                setIsLoaded(true);
                setHasError(true)
              } else {
                setIsLoaded(true);
                setDataCampaign(resData); // from API
                // setDataCampaign(data); // from local test

                // Step1: Check get_period_prize
                if(resData.get_period_prize) { 
                  setModalDetailPrize(true); 
                  setItemIndex(resData.get_period_prize - 1)

                } else { 
                  setModalDetailPrize(false) 

                  // Step2: Check get_grandprize
                  if(resData.get_grandprize) { 
                    setModalDetailGrandPrize(true)
                  } else { 
                    setModalDetailGrandPrize(false)
                    calcPlaceholderAnimate(resData.show_period)
                  } 
    
                }
                
                

              }

            } 
          }
        })
        .catch(err => {
          console.log(err);
          setIsLoaded(true);
          setHasError(true);
        });
    }

    // Call Fetch API
    fetchDataCampaign();

    return ()=> { 
      unamounted = true 
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkWindowWidth = useCallback(() => {
    // console.log('check position')
    const winW = window.innerWidth;
    setWindowWidthValue(winW);
  },[]);

  // const checkWindowHeight = useCallback(() => {
  //   // console.log('check position')
  //   const winH = window.innerHeight;
  //   setWindowHeightValue(winH);
  // },[]);

  const calcPlaceholderAnimate = (val) => {
    // console.log('window height:',windowHeightValue)
    setTimeout(() => {
      for(let i=0; i<val.length; i++) {
        if(val[i]) {
        document.getElementById('itemAnimate'+(i+1)).classList.add('active')
        }
      }
    }, 100);
  }


  const closeModalDetailGrandPrize = () => {
    setLoginFirst(false)
    setModalDetailGrandPrize(false)
    calcPlaceholderAnimate(dataCampaign.show_period)
  }

  const closeModalDetailPrize = () => {
    setModalDetailPrize(false)
    if(loginFirst) {
        // Step2: Check get_grandprize
        if(dataCampaign.get_grandprize) { 
          setTimeout(() => {
            setModalDetailGrandPrize(true)
          }, 750);
        } else { 
          setModalDetailGrandPrize(false)
          calcPlaceholderAnimate(dataCampaign.show_period)
        } 
    }

    setLoginFirst(false)

    // clearTimeout(checkGrandPrize)
  }

  // Check Resize
  useLayoutEffect(() => {
    window.addEventListener('resize',  checkWindowWidth );
    checkWindowWidth();


    // window.addEventListener('resize',  checkWindowHeight );
    // checkWindowHeight();

    return () => {window.removeEventListener('resize', checkWindowWidth); /* window.addEventListener('resize',  checkWindowHeight );*/ };
}, [/*checkWindowHeight,*/ checkWindowWidth])

  const calcPlaceholderItem = (amount, bg, placeholder, showperiod) => {
    let itemHeight = windowWidthValue / (amount === 4 ? 4.65 : amount === 8 ? 4.65 : 6.95)
    let bgSize = (amount / (amount === 12 ? 3 : 2)) * 100 + '%'

    if(amount === 4) {
      let itemArr = [Item1,Item2,Item3,Item4]
      let element4 = (id) => {
        return (
          <div key={id} id={`itemAnimate${id+1}`}  className={`item _${id+1} flip-card`} onClick={()=>{setModalDetailPrize(true); setItemIndex(id)}} style={showperiod[id] ? {height: itemHeight+'px'} : {height: itemHeight+'px', backgroundImage: 'url('+placeholder+')', backgroundSize: bgSize}}>
            <div className="inner" style={{transition: 'transform 1s '+(Math.random()/1.5)+'s'}}>
              <div className={`itemAnimate front`} style={{height: itemHeight+'px', backgroundImage: 'url('+placeholder+')', backgroundSize: bgSize,}}>
                <img src={itemArr[id]} alt={`placeholder${id+1}`} />
              </div>
              <div className={`itemAnimate back`} style={{height: itemHeight+'px', backgroundImage: 'url('+bg+')', backgroundSize: bgSize,}}></div>
            </div>
            {
              showperiod[id] ? null : <img src={itemArr[id]} alt={`placeholder${id+1}`} />
            }
          </div>
        )
      }
      return (
        <React.Fragment>
          <div className="container _4p">
            {
              [0,1].map((item,i)=>{
                return (element4(item))
              })
            }
          </div>
          <div className="container _4p">
            {
              [2,3].map((item,i)=>{
                return (element4(item))
              })
            }
          </div>
        </React.Fragment>
      )

    } else if (amount === 8) {
      let itemArr = [Item1,Item2,Item3,Item4,Item5,Item6,Item7,Item8]
      let element8 = (id) => {
        return (
          <div key={id} id={`itemAnimate${id+1}`}  className={`item _${id+1} flip-card`} onClick={()=>{setModalDetailPrize(true); setItemIndex(id)}} style={showperiod[id] ? {height: itemHeight+'px'} : {height: itemHeight+'px', backgroundImage: 'url('+placeholder+')', backgroundSize: bgSize}}>
            <div className="inner" style={{transition: 'transform 1s '+(Math.random()/1.5)+'s'}}>
              <div className={`itemAnimate front`} style={{height: itemHeight+'px', backgroundImage: 'url('+placeholder+')', backgroundSize: bgSize,}}>
                <img src={itemArr[id]} alt={`placeholder${id+1}`} />
              </div>
              <div className={`itemAnimate back`} style={{height: itemHeight+'px', backgroundImage: 'url('+bg+')', backgroundSize: bgSize,}}></div>
            </div>
            {
              showperiod[id] ? null : <img src={itemArr[id]} alt={`placeholder${id+1}`} />
            }
          </div>
        )
      }
      return (
        <React.Fragment>
          <div className="container _8p">
            {
              [0,1,2,3].map((item,i)=>{
                return (element8(item))
              })
            }
          </div>
          <div className="container _8p">
          {
              [4,5,6,7].map((item,i)=>{
                return (element8(item))
              })
            }
          </div>
        </React.Fragment>
        
      )

    } else if (amount === 12) {
      let itemArr = [Item1,Item2,Item3,Item4,Item5,Item6,Item7,Item8,Item9,Item10,Item11,Item12]
      let element12 = (id) => {
        return (
          <div key={id} id={`itemAnimate${id+1}`}  className={`item _${id+1} flip-card`} onClick={()=>{setModalDetailPrize(true); setItemIndex(id)}} style={showperiod[id] ? {height: itemHeight+'px'} : {height: itemHeight+'px', backgroundImage: 'url('+placeholder+')', backgroundSize: bgSize}}>
            <div className="inner" style={{transition: 'transform 1s '+(Math.random()/1.5)+'s'}}>
              <div className={`itemAnimate front`} style={{height: itemHeight+'px', backgroundImage: 'url('+placeholder+')', backgroundSize: bgSize,}}>
                <img src={itemArr[id]} alt={`placeholder${id+1}`} />
              </div>
              <div className={`itemAnimate back`} style={{height: itemHeight+'px', backgroundImage: 'url('+bg+')', backgroundSize: bgSize,}}></div>
            </div>
            {
              showperiod[id] ? null : <img src={itemArr[id]} alt={`placeholder${id+1}`} />
            }
          </div>
        )
      }
      return (
        <React.Fragment>
          <div className="container _12p">
            {
              [0,1,2,3].map((item,i)=>{
                return (element12(item))
              })
            }
          </div>
          <div className="container _12p">
            {
              [4,5,6,7].map((item,i)=>{
                return (element12(item))
              })
            }
          </div>
          <div className="container _12p">
            {
              [8,9,10,11].map((item,i)=>{
                return (element12(item))
              })
            }
          </div>
        </React.Fragment>
      )

    } else {
      return (
        <div>No Image</div>
      )
    }
  }

  const monthArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ษ.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.']
  const calcDurationDate = (valStart, valEnd) => {
    if(valEnd && valStart) {
      const startDay = moment(valStart).format('D')
      const endDay = moment(valEnd).format('D')
      const startMonth = Number(moment(valStart).format('MM'))
      const endMonth = Number(moment(valEnd).format('MM'))
      const fullStartYear = Number(moment(valStart).format('YYYY')) + 543
      const fullEndYear = Number(moment(valEnd).format('YYYY')) + 543
      const startYear = fullStartYear - 2500
      const endYear = fullEndYear - 2500
  // console.log(startMonth, endMonth)
  
      if(startMonth === endMonth) {
          return (startDay+' - '+endDay+' '+monthArr[endMonth -1]+' '+endYear)
      } else {
          if(startYear === endYear) {
              return (startDay+' '+monthArr[startMonth-1]+' - '+endDay+' '+monthArr[endMonth-1]+' '+endYear)
          } else {
              return (startDay+' '+monthArr[startMonth-1]+' '+startYear+' - '+endDay+' '+monthArr[endMonth-1]+' '+endYear)
          }
      }
    } else {
      console.error('start_date:',valStart,', end_date:',valEnd)
      return '-'
    }

  }

  Modal.setAppElement('#root');

  // Check loading
  if(!isLoaded) {
    return (
      <div className="App">
        <div className="App-loading">
          <p>...Loading...</p>
        </div>
      </div>
    );

   }  
   
  // Check error campaign
  if(hasError){
    return (
      <div className="App">
        <div className="App-error">
          {/* <p>campaign ID ไม่ถูกต้อง</p> */}
          <p>พบกิจกรรมใหม่เร็วๆนี้</p>
        </div>
      </div>
    );
  } 

  // Check obj empty of dataCampaign
  if(Object.keys(dataCampaign).length === 0){
    return (
      <div className="App">
        <div className="App-error">
          <p>เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
        </div>
      </div>
    );
  } 

  // Check dataCampaign has data
  if(dataCampaign) {
    // console.warn(Object.keys(dataCampaign).length)
    console.log('campaign id:',dataCampaign.campaign_id)

    return (
      <div className="App">
        <div className="logo-box">
          <img src={Logo} alt="logo" />
          <div className="description">
              { !!dataCampaign.campaign_data.description ? <p>{dataCampaign.campaign_data.description}</p> : null}
          </div>
        </div>

        <div className="grandprize-box">
          <div className="grandprize-button">
            <button onClick={()=>{setModalDetailGrandPrize(true)}}>
              <p>ดูรางวัลใหญ่</p>
              {
                dataCampaign.get_grandprize ? 
                <img src={GrandprizeActive} alt="grandprize" />
                :
                <img src={GrandprizeDisable} alt="grandprize" />
              }


            </button>
          </div>
        </div>

        <div style={{padding: '0 10px'}}>
          <div className="jigsaw-box">
            <div className="jigsaw-bg" style={{backgroundImage: `url(${dataCampaign.campaign_data.jigsaw_url})`}}>
            {
              calcPlaceholderItem(dataCampaign.campaign_data.no_period, dataCampaign.campaign_data.jigsaw_url, dataCampaign.campaign_data.placeholder_url, dataCampaign.show_period)
              // calcPlaceholderItem(dataCampaign.campaign_data.no_period, dataCampaign.campaign_data.jigsaw_url,  PlaceholderTest, dataCampaign.show_period) // Test
            }
            </div>
          </div>

        </div>

        <Modal 
            closeTimeoutMS={250}
            isOpen={modalDetailPrize} 
            className="_modal box-style modal-winner" 
            style={{ 
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.75)',
                    zIndex: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            }
        >
          <div className="_info-modal center">
              <div className="_body itemdetail">
                  <h1 className="modal-header">
                    {
                      dataCampaign.show_period[itemIndex] ?
                      'ยินดีด้วยคุณได้รับ'
                      : `รางวัลช่วงเวลาที่ ${itemIndex + 1}`
                    }
                  </h1>
                  <div className="modal-img">
                      {
                        dataCampaign.campaign_data.period_prize ? 
                          <React.Fragment>
                            <img src={dataCampaign.campaign_data.period_list[itemIndex].prize_picture_url} alt="item prize" />
                            {
                              dataCampaign.show_period[itemIndex] ?
                              <img className="_celebrate" src={Celebrate} alt="item prize celebrate" />
                              : null
                            }
                          </React.Fragment>
                        :
                          <img className="no-prize-img" src={NoPrize} alt="no item prize" />
                      }
                  </div>
                  {/* <h1 className="itemdetail-subheader">รางวัล</h1> */}
                  {
                    dataCampaign.campaign_data.period_prize ? 
                      <React.Fragment>
                        <h2 className="modal-name">{dataCampaign.campaign_data.period_list[itemIndex].prize_name}</h2>
                        <p className="modal-details">{dataCampaign.campaign_data.period_list[itemIndex].prize_details}</p>
                      </React.Fragment>
                    :
                      <h2 className="modal-name">ไม่มีของรางวัลในช่วงเวลานี้</h2>

                  }
                  <p className="modal-period">ล็อกอินระหว่างวันที่ {calcDurationDate(dataCampaign.campaign_data.period_list[itemIndex].start_date, dataCampaign.campaign_data.period_list[itemIndex].end_date)}</p>

                  <div style={{margin: 'auto', textAlign: 'center'}}>
                    <button className="btn-round _blue" onClick={()=>closeModalDetailPrize()}>
                      ตกลง
                    </button>
                  </div>
              </div>
          </div>
        </Modal>

        <Modal 
            closeTimeoutMS={250}
            isOpen={modalDetailGrandPrize} 
            className="_modal box-style modal-winner" 
            style={{ 
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.75)',
                    zIndex: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            }
        >
          <div className="_info-modal center">
              <div className="_body itemdetail">
                  
                    {
                      dataCampaign.get_grandprize ?
                      <h1 className="modal-header">ยินดีด้วยคุณทำภารกิจทุกช่วงเวลาสำเร็จ</h1>
                      : 
                      <React.Fragment>
                        <h1 className="modal-header" style={{marginBottom: '0'}}>รางวัลใหญ่</h1>
                        <h2 className="modal-subheader" style={{textAlign: 'center'}}>เมื่อทำภารกิจทุกช่วงเวลาสำเร็จ</h2>
                      </React.Fragment>
                    }
                  
                  <div className="modal-img">
                      <img src={dataCampaign.campaign_data.grandprize_picture_url} alt="item prize" />
                      {
                        dataCampaign.get_grandprize ?
                        <img className="_celebrate" src={Celebrate} alt="item prize celebrate" />
                        : null
                      }
                  </div>
                  {/* <h1 className="itemdetail-subheader">รางวัล</h1> */}
                  <h2 className="modal-name">{dataCampaign.campaign_data.grandprize_name}</h2>
                  <p className="modal-details">{dataCampaign.campaign_data.grandprize_details}</p>

                  <div style={{margin: 'auto', textAlign: 'center'}}>
                    <button className="btn-round _blue" onClick={()=>closeModalDetailGrandPrize()}>
                      ตกลง
                    </button>
                  </div>
              </div>
          </div>
        </Modal>
      </div>
    );
  }
    
  
}

export default App;
